.featureCard {
  border-radius: 16px;
  background: #e4f5ff;
  text-align: center;
  transition: box-shadow 0.3s ease;
  overflow: hidden;
}
.featureCard:hover {
  cursor: pointer;
}
.icon {
  width: Hug (64px) px;
  height: Hug (62px) px;
}
.title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2f3337;
}

.learnMore {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0041e4;
  text-decoration: none;
  display: inline-flex;
}

.arrowlearnmore {
  display: inline-flex;
  align-items: center;
}
.description {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  width: 90%;
  color: #73808f;
  text-align: center;
}
