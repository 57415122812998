.testimonials {
  background-color: #f5f8f8;
}

.testimonialheader h4 {
  color: #5f6974;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
}

.testimonialheader p {
  font-weight: 400;
  font-size: 14px;
  color: #73808f;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 16px;
  width: 100%;
  gap: 24px;
  border-radius: 16px;
  background: #f5f8f8;
  box-sizing: border-box;
}

.testimonialImg {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background: #d9d9d9;
}

.nameRole {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.nameRole h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #293241;
  font-family: "Poppins", sans-serif;
}

.nameRole p {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #5e5e5e;
  font-style: normal;
}

.testimonialText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;
  color: #5e5e5e;
}
