/* Navbar Styles */
.logo-size {
  width: 200px;
  height: auto;
}

.navbar-expand-lg .navbar-collapse {
  flex-grow: unset;
}

.custom-toggle,
.custom-toggle:focus {
  border: none;
  background: none;
  color: #000;
  padding: 0;
  width: 30px;
  height: 30px;
  outline: none;
  box-shadow: none;
}

.bars-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
}

.bars-icon .bar {
  width: 28px;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.cancel-icon {
  font-size: 1.7em;
}

.custom-collapse {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  transition: height 0.3s ease-in-out;
}

.navbar-nav {
  width: 100%;
  text-align: center;
}

.custom-dropdown-toggle {
  position: relative;
}

.custom-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.custom-dropdown-toggle:hover .custom-dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.nav-item {
  display: inline-block;
}

.apply-here-btn {
  background-color: var(--background-color-btn);
  border: none;
  transition: background-color 0.3s ease;
}

.apply-here-btn:hover {
  color: #fff;
  border: 1px solid var(--button-hover-color);
}

/* Overlay Effect and Menu Icon Adjustments */
.navbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  overflow-y: auto;
}

.menu-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: left;
  }

  .custom-collapse {
    position: relative;
    top: auto;
    left: auto;
  }

  .menu-icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .menu-icon {
    width: 18px;
    height: 18px;
  }
}

/* Dropdown Visibility on Small Screens */
.navbar-collapse {
  background-color: white;
  position: relative;
  z-index: 1000;
}
