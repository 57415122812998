.scrolling-row {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.scrolling-content {
  display: flex;
  animation: scrollLeft 20s linear infinite;
}

.logo-img {
  max-height: 60px;
  object-fit: contain;
  margin: 0 20px;
}

/* Keyframes for smooth scrolling */
@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
