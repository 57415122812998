.slick-slide {
  padding: 0 10px;
}

.card {
  margin: 10px;
}

.course-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 450px; /* Ensures all cards have the same height */
  border-radius: 8px;
  overflow: hidden;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.card-img {
  height: 200px; /* Consistent image height */
  object-fit: cover;
}

.course-title {
  font-size: 10px;
  font-weight: 600;
}

.course-desc {
  font-size: 12px;
  line-height: 20px;
}

.course-info {
  font-size: 12px;
  margin-bottom: 5px;
}

.course-reviews {
  font-size: 10px;
  margin-bottom: 5px;
}

button.learning-mode-button {
  margin: 0 3px 3px 0;
  font-size: 8.5px;
  padding: 5px;
}
